<template>
  <div>
    <v-parallax
      dark
      height="300"
      src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h1>Добро пожаловать на сайт!</h1>
          <h4>В меню вы сможете выбрать категории</h4>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  text-align: center;
}
</style>
